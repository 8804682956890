import React from "react"
import { Layout } from "@/layout"
import {
  Box,
  SEO,
  Width,
  Link,
  Text,
  Instagram,
  MentorFooter,
  MentorHeader,
} from "@/components/index"
import Img from "gatsby-image"

export default function Mentorship({ data }) {
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Meet Sonora’s Mentors: Jordan Peters"
        description="Sonora pairs world-class mentors with intermediate guitar students in an intensive 3-month program to help them find their breakthrough on guitar."
      />
      <MentorHeader
        name="Jordan Peters"
        videoUrl="https://vimeo.com/496729344/a9b411d5d7"
      />
      <Width pt="12.9rem" pb="7.2rem">
        <Box
          width={["100%", null, 10 / 12, null]}
          mx="auto"
          display="flex"
          flexDirection={["column", null, "row", null]}
        >
          <Box width={["100%", null, 4 / 12, null]} pr="2em">
            <Box borderRadius=".6rem" overflow="hidden">
              <Img
                alt={"curt"}
                fluid={data.jordanPetersImage.childImageSharp.fluid}
              />
            </Box>
          </Box>
          <Box flex="2">
            <Text as="h1" fontWeight="bold" mb="1.2rem" mt={["2rem", null, "0rem", null]}>
              Jordan Peters
            </Text>
            <Text level="1" mb="1.2rem">
              Guitarist and producer
            </Text>
            <Box>
              <Link to="https://www.instagram.com/jordanpetersmusic/" as="a">
                <Instagram />
              </Link>
            </Box>
            <Text level="2" mt="3.6rem">
              Jordan Peters is a Brooklyn-based guitarist and producer whose
              influences range from{" "}
              <strong>jazz, hip-hop, blues, folk, rock, funk, soul</strong> and{" "}
              <strong>electronic music</strong>. Originally from Lachute Québec,
              he has worked, toured, performed and recorded with numerous
              artists such as{" "}
              <strong>
                Lauryn Hill, Renée Elise Goldsberry, Jon Batiste, The Weeknd,
                NAS, Billy Porter, Quiñ, The Roots, Andra Day, Antonio Sanchez,
                Tony Allen, Femi Kuti, Darlene Love, Bokanté, Alex Clare
              </strong>{" "}
              and <strong>Renee Neufville</strong>. He has appeared the Tonight
              Show, NPR Tiny Desks, Austin City Limits, The View, CBS This
              Morning, NBC’s Annual Christmas Tree Lighting, BET Black Girls
              Rock.
            </Text>
          </Box>
        </Box>
      </Width>
      <MentorFooter data={data} name="Jordan Peters" />
    </Layout>
  )
}

/* Image and Other GQL Queries */
export const mentorshipQuery = graphql`
  query {
    jordanPetersImage: file(
      relativePath: { eq: "jordanPeters-L.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 824, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    levelupImage: file(relativePath: { eq: "levelup.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    curriculumImage: file(relativePath: { eq: "curriculum.png" }) {
      childImageSharp {
        fluid(maxWidth: 570, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    communityImage: file(relativePath: { eq: "community.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    locationImage: file(relativePath: { eq: "location.png" }) {
      childImageSharp {
        fixed(width: 14, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
